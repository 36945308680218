<template>
    <div>
        <app-header title="批量导入排房信息" :isShowBack="true"></app-header>
        <div class="content">
            <div class="content_step">
                <el-steps :active="active" finish-status="success">
                    <el-step title="导出房间列表"></el-step>
                    <el-step title="填写排房信息"></el-step>
                    <el-step title="导入排房信息"></el-step>
                </el-steps>
            </div>
            <!-- 第一步 -->
            <div class="content_step1" v-if="active==0">
                <div class="content_row content_title">第一步 导出房间列表</div>
                <div class="content_row" style="display: flex;">
                    <div style="height: 35px;line-height: 35px;">选择房源/楼栋/房间</div>
                    <div class="content_selectRoom" @click="selectRoomFun()">{{showText}}</div>
                    <el-button type="primary" @click="exportFun()">导出房源</el-button>
                </div>
                <div class="content_tip">注意：一次批量导入的房源房源名称必须相同，不支持一次导入多个房源下的房间。</div>
                <div class="content_row">请先导出房源，如已完成，请直接点击“下一步”。</div>
            </div>
            <!-- 第二步 -->
            <div class="content_step1" v-if="active==1">
                <div class="content_row content_title">第二步 在导出的房间信息表格中填写排房信息</div>
                <div class="content_row">请在第一步导出的房间信息表中，填写排房的入住人信息，包括姓名、性别、手机号码、入住起止日期；</div>
                <div class="content_tip">注意：一个房间可入住多人，可在入住人信息行添加行；</div>
                <div class="content_row">如已完成，请直接点击“下一步”。</div>
            </div>
            <!-- 第三步 -->
            <div class="content_step1" v-if="active==2">
                <div class="content_row content_title">第三步 导入批量排房信息</div>
                <div class="content_row">选择已整理好的批量排房信息，点击“导入"按钮。</div>
                <div class="content_tip">注意：一次导入不能超过2000个人。</div>
                <div v-if="exportLoading">
                    <div class="change_icon">
                        <i class="el-icon-loading"></i>
                    </div>
                    <span class="content_row" style="position: relative; top: -6px;">批量导入可能需要一点时间，请耐心等待导入完成。</span>
                </div>
            </div>

            <el-button v-if="active==0 || active ==1" type="primary" style="margin-top: 12px;width:200px;"
                @click="next">下一步</el-button>
            <div v-if="active == 2" class="up_add_bg" @click='onImgFunBtn'>
                导入
                <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload" multiple='false'
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
            </div>
            <div v-if="active==1">
                <div class="content_demo">填写规范样例:</div>
                <img src="../../assets/rowHouseDemoPic.jpg">
            </div>
        </div>
        <div class="selectRoom_cont">
            <el-dialog title="选择房源/楼栋/房间" :visible.sync="dialogVisible" :show-close="false"
                :close-on-click-modal="false">

                <div class="main-content" style="padding-bottom: 33px;">
                    <div class="lt">
                        <div class="lt-title">房源</div>
                        <template v-if="list.length > 0">
                            <div v-for="(item,index) in list" :key="index">
                                <div class="row" :style="clickHouseIndex == index ? 'color: #30BAC1;' : ''">
                                    <img @click="checkHouse(item,index,item.isCheck)" class="row-icon"
                                        v-if="item.isCheck" src="../../assets/danxuan2@2x.png">
                                    <img @click="checkHouse(item,index,!item.isCheck)" class="row-icon"
                                        v-if="!item.isCheck" src="../../assets/danxuan@2x.png">
                                    <div @click="checkHouse(item,index)"
                                        :style="clickHouseIndex == index ? 'color: #30BAC1;' : ''">{{item.name}}</div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div style="margin-left: 28px;padding-top:50px;">暂无数据</div>
                        </template>

                    </div>
                    <div class="cent">
                        <div class="cent-title">楼栋</div>
                        <div class="row_box">
                            <div class="row">
                                <img class="row-icon" @click="selectAllBuild()" v-if="selectAllBuildFlag == 0"
                                    src="../../assets/ic_unselect.png">
                                <img class="row-icon" @click="selectAllBuild()" v-if="selectAllBuildFlag == 1"
                                    src="../../assets/ic_select.png">
                                <img class="row-icon" @click="selectAllBuild()" v-if="selectAllBuildFlag == 2"
                                    src="../../assets/ic_select_half.png">
                                <span>全选</span></div>
                            <template v-if="buildList.length > 0">
                                <div v-for="(item,index) in buildList" :key="index" style="padding-left: 20px;">
                                    <div class="row">
                                        <img class="row-icon" @click="selectBuild(item,index)"
                                            v-if="!item.isAuth || item.isAuth == 0" src="../../assets/ic_unselect.png">
                                        <img class="row-icon" @click="selectBuild(item,index)" v-if="item.isAuth == 1"
                                            src="../../assets/ic_select.png">
                                        <img class="row-icon" @click="selectBuild(item,index)" v-if="item.isAuth == 2"
                                            src="../../assets/ic_select_half.png">
                                        <div @click="clickBuild(item,index)"
                                            :style="clickBuildIndex == index ? 'color: #30BAC1;' : ''">
                                            {{item.buildName}}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div style="margin-left: 28px;padding-top:50px;">暂无数据</div>
                            </template>

                        </div>

                    </div>

                    <div class="rt">
                        <div class="rt-title">房间</div>
                        <div class="row_box">
                            <div class="row">
                                <img class="row-icon" @click="selectAllRoom()" v-if="selectAllRoomFlag == 0"
                                    src="../../assets/ic_unselect.png">
                                <img class="row-icon" @click="selectAllRoom()" v-if="selectAllRoomFlag == 1"
                                    src="../../assets/ic_select.png">
                                <img class="row-icon" @click="selectAllRoom()" v-if="selectAllRoomFlag == 2"
                                    src="../../assets/ic_select_half.png">
                                <span>全选</span></div>
                            <template v-if="roomList.length >0">
                                <div v-for="(it,idx) in roomList" :key="idx" style="padding-left: 20px;">
                                    <div class="row" @click="selectRoom(it,idx)">
                                        <img class="row-icon" v-if="it.checked" src="../../assets/ic_select.png">
                                        <img class="row-icon" v-if="!it.checked" src="../../assets/ic_unselect.png">
                                        <div>{{it.roomNo}}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div style="margin-left: 28px;padding-top:50px;">暂无数据</div>
                            </template>

                            <!-- <div v-if="clickBuildIndex == index && item.roomList.length == 0">
                                    <div class="notData">暂无房间数据~</div>
                                </div> -->
                        </div>


                    </div>
                </div>
                <div class="selectRoom_bottom">
                    <el-button @click="cancelBtn">取消</el-button>
                    <el-button @click="selectSure()" type="primary">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                active: 0,
                exportLoading: false,
                dialogVisible: false,
                showText: '',
                disableFlag: false,
                authAccount: 0, //当前楼栋房间选择的个数
                clickBuildIndex: 0,
                clickHouseIndex: 0,
                buildList: [],
                roomList: [],
                list: [],
                selectBuildAuthAccount: 0, //楼栋全选个数
                selectRoomAuthAccount: 0, //房间选择个数
                checkHouseFlage: false,
                checkedApartmentDto: {}, //勾选的数据
                formData: new FormData(),
                file: {}, //文件数据 
                fileName: '',
                checkedHouseObj: {},
                checkedHouseIndex: 0,
                userInfo: {},
                selectAllBuildFlag: false, //楼栋全选
                selectAllRoomFlag: false, //房间全选
                cacheList: '[]',
            }
        },
        activated() {
            // 初始化
            this.active = 0;
            this.exportLoading = false;
            this.showText = "";
            this.clickHouseIndex = 0;
            this.clickBuildIndex = 0;
            this.checkedHouseObj = {};
            this.checkedHouseIndex = 0;
            this.checkedApartmentDto = {};
            this.selectAllBuildFlag = false;
            this.selectAllRoomFlag = false;
            this.list = [];
            this.cacheList = '[]';
            this.userInfo = this.cache.getLS('userInfo');
        },
        deactivated() {
            this.dialogVisible = false;
        },
        methods: {
            exportFun() {
                if (JSON.stringify(this.checkedApartmentDto) == '{}') {
                    return this.$message({
                        message: '请选择房间',
                        type: 'warning'
                    })
                }
                axios({
                        method: "POST",
                        url: this.EXport + '/tenant-service/lease/v1/batch-plan/batch-file/create',
                        responseType: "blob",
                        data: this.checkedApartmentDto,
                        headers: {
                            accessToken: localStorage.getItem("token") || sessionStorage.getItem("token")
                        }
                    })
                    .then(response => {
                        var elink = document.createElement("a");
                        console.log(elink);
                        elink.download = "房源.xlsx";
                        elink.style.display = "none";
                        var blob = new Blob([response.data]);
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        document.body.removeChild(elink);
                    })
                    .catch(error => {});
            },
            next() {
                if (this.active < 2) {
                    this.active = this.active + 1;
                }
            },
            cancelBtn() {
                this.dialogVisible = false;
                console.log('关闭')
                this.list = JSON.parse(this.cacheList)
                this.refreshList();
            },
            //将选中确定的数据保存起来 
            saveCacheHouses() {
                new Promise((resolve, reject) => {
                    this.cacheList = JSON.stringify(this.list)
                })
            },
            selectSure() {
                let checkedApartment = {};
                let selectText = '';
                let list = this.list;
                this.saveCacheHouses();
                list.forEach((item1, index1) => {
                    // 将当前房源下勾选的数据保存 此数据用于导出入参
                    if (this.apartmentId == item1.id) {
                        let checkedBuildList = [];
                        if (item1.buildList && item1.buildList.length > 0) {
                            item1.buildList.forEach((item2, index2) => {
                                let checkedRoomList = [];
                                if (item2.roomList && item2.roomList.length > 0) {
                                    item2.roomList.forEach((item3, index3) => {
                                        if (item3.checked) {
                                            checkedRoomList.push(item3.roomNo)
                                            if (selectText) {
                                                selectText = selectText + '、' + item1.name +
                                                    '/' +
                                                    item2.buildName +
                                                    '/' + item3.roomNo;
                                            } else {
                                                selectText = item1.name + '/' +
                                                    item2.buildName +
                                                    '/' + item3.roomNo;
                                            }
                                        }

                                    })
                                }
                                if (item2.isAuth) {
                                    checkedBuildList.push({
                                        roomList: checkedRoomList,
                                        buildName: item2.buildName,
                                        buildCode: item2.buildCode,
                                    })
                                }

                            })
                        }
                        if (this.clickHouseIndex == index1 && item1.isCheck) {
                            checkedApartment = {
                                buildList: checkedBuildList,
                                apartmentName: item1.name,
                                apartmentId: item1.id,

                            }
                            this.showText = selectText;
                        }
                    } else {
                        // 初始化非当前选择房源下的数据勾选状态
                        if (item1.buildList && item1.buildList.length > 0) {
                            item1.buildList.forEach(item2 => {
                                item2.isAuth = 0;
                                if (item2.roomList && item2.roomList.length > 0) {
                                    item2.roomList.forEach(item3 => {
                                        item3.checked = false;
                                    })
                                }
                            })
                        }
                    }

                })
                this.checkedApartmentDto = checkedApartment;
                if (!this.showText) {
                    return this.$message({
                        message: '至少要选择一间房间',
                        type: 'warning'
                    })
                } else {
                    this.dialogVisible = false;
                }
            },
            selectRoomFun() {
                this.dialogVisible = true;
                if (this.list.length == 0) {
                    this.getHouseList().then(res => {
                        this.initList();
                    });
                } else {
                    this.checkedHouseObj = {};
                    this.checkedHouseIndex = 0;
                    this.list.find((item, index) => {
                        if (this.checkedApartmentDto.apartmentId == item.id) {
                            return this.checkedHouseObj = item
                        }
                    })
                    this.list.findIndex((item, index) => {
                        if (this.checkedApartmentDto.apartmentId == item.id) {
                            return this.checkedHouseIndex = index
                        }
                    })

                    if (JSON.stringify(this.checkedHouseObj) == "{}") {
                        this.checkHouse(this.list[0], 0);
                    } else {
                        this.checkHouse(this.checkedHouseObj, this.checkedHouseIndex);
                    }

                }


            },
            initList() {
                //默认勾选第一个
                this.checkHouse(this.list[this.clickHouseIndex], this.clickHouseIndex);
            },

            // 选择房源
            checkHouse(row, rowIndex) {
                // this.selectAllBuildFlag = false;
                this.clickHouseIndex = rowIndex;
                this.apartmentId = row.id;
                this.roomList = [];
                this.getBuildList().then(list => {
                    // //判断房源下是否存在楼栋 
                    if (list[this.clickHouseIndex].buildList.length > 0) {
                        // 默认请求对应房源下第一栋楼下的房间数据
                        this.clickBuild(list[this.clickHouseIndex].buildList[0], 0);
                    }
                    list.forEach((item, index) => {
                        if (index == rowIndex) {
                            item.isCheck = true;
                            this.clickBuildIndex = 0;
                            this.buildList = item.buildList;
                            this.refreshIsCheckBuildAll(this.buildList)
                        } else {
                            item.isCheck = false;
                        }
                    })

                    this.$forceUpdate();

                })


            },
            refreshList() {
                if (!this.list) {
                    return
                }
                this.list.forEach((item, index) => {
                    if (this.checkedHouseIndex != index) {
                        // 初始化非当前选择房源下的数据勾选状态
                        if (item.buildList && item.buildList.length > 0) {
                            item.buildList.forEach(item1 => {
                                item1.isAuth = 0;
                                if (item1.roomList && item1.roomList.length > 0) {
                                    item1.roomList.forEach(item2 => {
                                        item2.checked = false;
                                    })
                                }
                            })
                        }
                    }
                })
            },
            // 刷新了楼栋全选
            refreshIsCheckBuildAll(buildList) {
                let authCount = 0;
                buildList.forEach(item => {
                    if (item.isAuth == 1) {
                        authCount++
                    }
                })
                if (authCount == 0) {
                    this.buildList.find(it => {
                        return this.selectAllBuildFlag = it.isAuth == 2 ? 2 : 0;
                    })
                } else if (authCount == buildList.length) {
                    this.selectAllBuildFlag = 1
                } else {

                    this.selectAllBuildFlag = 2
                }
                console.log('selectAllBuildFlag:', this.selectAllBuildFlag, authCount)
            },
            // 刷新全选房间
            refreshIsCheckRoomAll(roomList) {
                let authCount = 0;
                roomList.forEach(item => {
                    if (item.checked) {
                        authCount++
                    }
                })
                if (authCount == 0) {
                    this.selectAllRoomFlag = 0;
                    this.buildList[this.clickBuildIndex].isAuth = 0;
                } else if (authCount == roomList.length) {
                    this.selectAllRoomFlag = 1;
                    this.buildList[this.clickBuildIndex].isAuth = 1;
                } else {
                    this.selectAllRoomFlag = 2;
                    this.buildList[this.clickBuildIndex].isAuth = 2;
                }
                console.log('selectAllRoomFlag:', this.selectAllRoomFlag)

            },
            // 点击楼栋
            clickBuild(row, rowIndex) {
                this.clickBuildIndex = rowIndex;
                this.buildingNo = row.buildCode;
                this.getRoomList().then(buildList => {
                    buildList.forEach((item, index) => {
                        if (index == rowIndex) {
                            this.roomList = item.roomList ? item.roomList : [];
                            if (item.isAuth == 1) {
                                this.roomList.forEach((roomItem, roomIndex) => {
                                    roomItem.checked = true;
                                })
                            }

                        }
                    })

                    this.refreshIsCheckRoomAll(this.roomList);
                    this.refreshIsCheckBuildAll(buildList)
                    this.$forceUpdate();
                })

            },

            // 选择楼栋
            selectBuild(row, rowIndex) {
                this.clickBuildIndex = rowIndex;
                this.buildingNo = row.buildCode;
                this.getRoomList().then(buildList => {
                    // isAuth 0--不选 1--全选 2--半选
                    if (!buildList || buildList.length == 0) {
                        return
                    }
                    buildList.forEach((item, index) => {
                        if (index == rowIndex) {
                            item.checked = !item.checked;
                            if (item.checked) {
                                item.isAuth = 1;
                            } else {
                                item.isAuth = 0;
                            }
                            this.selectAllRoomFlag = item.isAuth;
                            if (item.roomList.length > 0) {
                                item.roomList.forEach(it => {
                                    if (item.checked) {
                                        it.checked = true;
                                    } else {
                                        it.checked = false;
                                    }
                                })
                            }
                            this.roomList = item.roomList ? item.roomList : [];
                        }
                    })
                    this.buildList = buildList;
                    this.refreshIsCheckBuildAll(buildList)
                })
            },
            // 全选楼栋
            selectAllBuild() {
                console.log('全选楼栋')
                let buildList = this.list[this.clickHouseIndex].buildList ? this.list[this.clickHouseIndex].buildList :
                    [];
                if (!buildList || !buildList.length) {
                    return
                }
                this.selectAllBuildFlag = this.selectAllBuildFlag == 1 ? 0 : 1;
                if (this.selectAllBuildFlag == 1) {
                    this.getAllListByHouse();
                }
                buildList.forEach((item, index) => {
                    item.isAuth = this.selectAllBuildFlag ? 1 : 0;
                    item.roomList = item.roomList ? item.roomList : [];
                    item.roomList.forEach((roomItem, roomIndex) => {
                        roomItem.checked = item.isAuth == 1;
                    })


                })
                this.buildList = buildList;
                this.roomList = this.buildList[this.clickBuildIndex].roomList ? this.buildList[this.clickBuildIndex]
                    .roomList : [];
                this.refreshIsCheckRoomAll(this.roomList);
            },
            // 全选房间
            selectAllRoom() {
                if (!this.buildList || !this.buildList.length || !this.roomList || !this.roomList.length) {
                    return
                }
                this.selectAllRoomFlag = this.selectAllRoomFlag == 1 ? 0 : 1;
                this.buildList[this.clickBuildIndex].isAuth = this.selectAllRoomFlag;
                let roomList = this.roomList ? this.roomList : [];
                roomList.forEach((item, index) => {
                    item.checked = this.selectAllRoomFlag == 1;
                })
                this.refreshIsCheckBuildAll(this.buildList)
                this.$forceUpdate();
            },
            // 选择房间
            selectRoom(row, rowIndex) {
                if (this.roomList.length == 0) {
                    return
                }
                this.roomList.forEach((item, index) => {
                    if (index == rowIndex) {
                        item.checked = !item.checked;
                    }
                })

                this.refreshIsCheckRoomAll(this.roomList);
                this.refreshIsCheckBuildAll(this.buildList)
                this.$forceUpdate();

            },
            onImgFunBtn() {
                document.getElementById('fileupload').click();
            },
            //选择文件
            addFile: function (e) {
                var _this = this;
                let inputDOM = this.$refs.inputer;
                this.file = inputDOM.files[0];
                let len = this.file.length;
                let size = Math.floor(this.file.size / 1024);
                if (size > 50 * 1024 * 1024) {
                    alert("请选择50M以内的文件！");
                    return false;
                }
                this.formData.append("multipartFile", this.file);
                this.exportLoading = true;
                axios({
                        method: "POST",
                        url: _this.UPLOAD_URL + "/tenant-service/lease/v1/batch-plan/exec-batch",
                        data: this.formData,
                        headers: {
                            accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
                            securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem(
                                "securityKey"),
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then((response) => {
                        this.formData = new FormData();
                        this.fileName = document.getElementById('fileupload').files[0].name;
                        if (response.data.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '导入成功!'
                            })
                            this.$back();
                        } else {
                            this.$message({
                                type: 'warning',
                                message: response.data.msg
                            })
                        }
                        e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
                        this.exportLoading = false;
                    })
                    .catch((error) => {
                        this.formData = new FormData();
                        e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
                    });
            },
            // 获取房源
            getHouseList() {
                return new Promise((resolve, reject) => {
                    let url = "/landlord-service/house/v1/apartment/query-all-house";
                    this.post(url, {}).then(data => {
                        this.list = data;
                        resolve(data);
                    })
                });

            },
            // 获取楼栋
            getBuildList() {
                let _this = this;
                return new Promise((resolve, reject) => {
                    let dto = {
                        apartmentId: _this.apartmentId,
                    }
                    let url = "/landlord-service/house/v1/apartment/query-all-build";
                    _this.post(url, dto).then(data => {
                        if (!_this.list[_this.clickHouseIndex].buildList) {
                            _this.list[_this.clickHouseIndex].buildList = [];
                        }
                        if (_this.list[_this.clickHouseIndex].buildList.length == 0) {
                            _this.list[_this.clickHouseIndex].buildList = data;
                        }
                        resolve(_this.list)
                    })
                })

            },
            // 获取房间
            getRoomList() {
                return new Promise((resolve, reject) => {
                    let dto = {
                        apartmentId: this.apartmentId,
                        buildingNo: this.buildingNo,
                    }
                    let url = "/landlord-service/house/v1/apartment/query-all-room";
                    this.post(url, dto).then(data => {
                        if (this.list[this.clickHouseIndex].buildList && this.list[this.clickHouseIndex]
                            .buildList.length > 0) {
                            let buildList = this.list[this.clickHouseIndex].buildList ? this.list[this
                                .clickHouseIndex].buildList : [];
                            if (!buildList[this.clickBuildIndex].roomList) {
                                buildList[this.clickBuildIndex].roomList = []; //初始化
                            }
                            if (buildList[this.clickBuildIndex].roomList.length == 0) {
                                buildList[this.clickBuildIndex].roomList = data;
                            } else {
                                // 保存选择的数据状态
                                if (data && data.length > 0) {
                                    data.forEach((dataItem, dataIndex) => {
                                        buildList[this.clickBuildIndex].roomList.forEach((
                                            roomItem, roomIndex) => {
                                            if (dataItem.id == roomItem.id) {
                                                dataItem.checked = roomItem.checked;
                                            }
                                        })
                                    })
                                    buildList[this.clickBuildIndex].roomList = data;
                                } else {
                                    buildList[this.clickBuildIndex].roomList = data;
                                }
                            }
                            resolve(buildList)
                        }
                    })
                })

            },
            // 根据房源获取该房源下的数据 （为了实现楼栋全选而请求的数据）
            getAllListByHouse() {
                let dto = {
                    apartmentId: this.apartmentId,
                    leaseType: 1, //长租房间
                };
                this.post('landlord-service/house/v1/apartment/query-all-house-tree', dto).then(res => {
                    let allListData = res ? res : [];
                    allListData.forEach((item, index) => {
                        item.buildList = item.buildingList;
                        if (item.buildList) {
                            item.buildList.forEach((buileItem, buileIndex) => {
                                buileItem.isAuth = this.selectAllBuildFlag;
                                buileItem.checked = this.selectAllBuildFlag == 0 ? false : true;
                                if (buileItem.roomList) {
                                    if (buileItem.isAuth == 1) {
                                        buileItem.roomList.forEach((roomItem, roomIndex) => {
                                            roomItem.checked = true;
                                        })
                                    } else {
                                        buileItem.roomList.forEach((roomItem, roomIndex) => {
                                            roomItem.checked = false;
                                        })
                                    }

                                }
                            })
                        }
                    })
                    this.list.forEach(item => {
                        if (item.id == dto.apartmentId) {
                            // item.buildList 返回的数据包含无房间的楼栋 因此使用这种方法
                            if (allListData && allListData.length > 0) {
                                allListData[0].buildList.forEach(it => {
                                    item.buildList.forEach(it1 => {
                                        if (it1.buildCode == it.buildCode) {
                                            it1 = it;
                                        }
                                    })
                                })
                            }
                        }
                    })
                    this.$forceUpdate();
                    console.log('allListData:', allListData)

                })
            }


        }

    }
</script>
<style lang="scss" scoped>
    .content {
        padding: 20px 48px;
    }

    .content_step {
        width: 800px;
        margin-bottom: 80px;

    }

    .content_row {
        padding: 10px 0;
    }

    .content_title {
        font-weight: 600;
    }

    .content_tip {
        color: red;
        padding-bottom: 20px;
    }

    .content_demo {
        padding: 20px 0 10px 0;
    }

    .change_icon {
        display: inline-block;
        margin-right: 10px;
        font-size: 35px;
    }

    .content_selectRoom {
        width: 400px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 20px;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 35px;
        line-height: 35px;
        cursor: pointer;
        overflow: hidden;
        box-sizing: border-box;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }

    .selectRoom_bottom {
        text-align: center;
    }

    .main-content {
        display: flex;
        padding: 22px 22px;
        height: 500px;
        overflow: auto;

    }

    .lt,
    rt,
    .cent {
        width: 33%;
        height: 100%;
    }

    .lt {
        border-right: 1px solid #eeeeee;
    }

    .lt-title,
    .rt-title,
    .cent-title {
        height: 44px;
        line-height: 44px;
        font-size: 16x;
        font-weight: 600;
        padding-left: 10px;
    }

    .row {
        padding: 3px 9px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
    }

    .row-icon {
        width: 16px;
        height: 16px;
        top: 3px;
        position: relative;
        padding-right: 3px;
    }

    .mian-submit-box {
        width: 100%;
        background-color: #FFFFFF;
        margin: 0 auto;
        position: fixed;
        bottom: 0;
        padding-bottom: 8px;
    }

    .mian-submit {
        width: 289px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 10px;
        color: #FFFFFF;
        background-color: #30BAC1;
        font-size: 16px;
        margin: 0 45px;
    }

    .notData {
        margin-top: 25px;
        margin-left: 50px;
        text-align: center;
    }

    .up_add_bg {
        width: 200px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        background: #30BAC1;
        border: 1px solid #30BAC1;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 6px;
        cursor: pointer;

        .upload-btn {
            opacity: 0;
        }
    }
</style>